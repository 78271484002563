import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Copyright from "../components/Copyright";
import Logo from "../components/Logo";
import useStyles from "../helpers/styles";
import Wrapper from "../helpers/FormWrapper";
import * as CognitoService from "../services/cognito.service.js";
import "../helpers/getDestUrl";

const SigninForm = () => {
  const classes = useStyles();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location);
      const referrer = url.searchParams.get("ref");

      if (referrer) {
        sessionStorage.setItem("referrer", referrer);
        url.searchParams.delete("ref");
        window.history.pushState(null, "", url.toString());
      }
    }
  }, []);

  const onSubmit = () => {
    CognitoService.signOut();

    if (typeof window !== "undefined") {
      let returnUrl = "";

      if (typeof window !== "undefined") {
        if (sessionStorage.getItem("referrer"))
          returnUrl = sessionStorage.getItem("referrer");
        else returnUrl = "https://mapquest.com/";
      }

      window.location = returnUrl + "?logout=true";
    }

    return false;
  };

  const onCancel = () => {
    if (typeof window !== "undefined") {
      let returnUrl = "";

      if (typeof window !== "undefined") {
        if (sessionStorage.getItem("referrer"))
          returnUrl = sessionStorage.getItem("referrer");
        else returnUrl = "https://mapquest.com/";
      }

      window.location = returnUrl;
    }
    return false;
  };

  return (
    <div className={classes.paper}>
      <Logo />
      <Typography>Are you sure you want to sign out?</Typography>
      <form className={classes.form} onSubmit={onSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              type="button"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="primary"
              className="submit-btn"
            >
              Sign Out
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="button"
              onClick={onCancel}
              fullWidth
              variant="contained"
              color="secondary"
              className="cancel-btn"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <Box className={classes.box} mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  );
};

export default function SignIn() {
  return (
    <Wrapper>
      <SigninForm />
    </Wrapper>
  );
}
